<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Product List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top: 27px"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add"
										id="btn"
										color="success"
										style="margin-top: 27px; margin-left: 5px"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height: 900px">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								:detail-row-component="detailRow"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="showDetail(props.rowData.id)"
										color="secondary"
										size="sm"
										style="margin-left: 0px"
										class="py-0"
									>
										<CIcon name="cil-chevron-double-down" />
									</CButton>
									<CButton
										@click="add(props.rowData)"
										color="warning"
										size="sm"
										class="py-0"
									>
										<CIcon name="cil-pencil" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol md="12">
					<CInput label="Nama" v-model="frm.nama" placeholder="Nama" />
				</CCol>
			</CRow>
			<CRow>
				<CCol md="4">
					<CInput
						label="Harga Beli"
						type="number"
						v-model.number="frm.hargaBeli"
						placeholder="Harga Beli"
					/>
				</CCol>
				<CCol md="4">
					<CInput
						label="Harga"
						type="number"
						v-model.number="frm.harga"
						placeholder="Harga"
					/>
				</CCol>
				<CCol md="4">
					<CInput
						label="Lokasi"
						v-model="frm.lokasi"
						placeholder="Lokasi A.33"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="6">
					<div role="group" class="form-group">
						<label for="produksi" class=""> Produksi </label>
						<select v-model="frm.produksi" id="produksi" class="form-control">
							<option data-key="import" value="import">import</option>
							<option data-key="sendiri" value="sendiri">sendiri</option>
						</select>
					</div>
				</CCol>
			</CRow>
			<template #header>
				<h6 class="modal-title">
					{{ selectedId != null ? "Edit " + frm.nama : "Add new data" }}
				</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton @click="save" :disabled="loading" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";
import ProductDetail from "./ProductDetail";

export default {
	components: {
		ProductDetail,
	},
	data() {
		return {
			uri: basuri + "product",
			moreParams: { filter: null, t_: Date.now() },
			selectedId: null,
			loading: false,
			modal: false,
			detailRow: ProductDetail,
			frm: {
				nama: "",
				harga: 0,
				hargaBeli: 0,
				lokasi: 0,
				produksi: "import",
			},
			fields: [
				{
					name: "kode",
					title: "Kode",
					sortField: "kode",
				},
				{
					name: "nama",
					title: "Nama",
					sortField: "nama",
				},
				{
					name: "hargaBeli",
					title: "Harga Beli",
					sortField: "hargaBeli",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "harga",
					title: "Harga",
					sortField: "harga",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "stock",
					title: "Stock",
					sortField: "stock",
				},
				{
					name: "lokasi",
					title: "Lokasi",
					sortField: "lokasi",
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function (val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, t_: Date.now() };
			//this.$refs.vuetable.refresh();
		},
		add(data = null) {
			if (data) {
				this.frm = JSON.parse(JSON.stringify(data));
				this.selectedId = data.id;
			} else {
				this.frm = {
					nama: "",
					harga: 0,
					hargaBeli: 0,
					lokasi: 0,
					produksi: "import",
				};
				this.selectedId = null;
			}
			this.modal = true;
		},
		save: debounce(async function () {
			this.loading = true;

			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.put(
						"product/" + this.selectedId,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post("product", this.frm);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),
	},
};
</script>
