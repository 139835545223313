<template>
	<div>
		<CRow :key="el.id" v-for="(el, i) in rowData.productItems">
			<div class="col-md-3">
				<div role="group" class="form-group">
					<label for="size" class=""> Size {{ el.kode }} </label><br />
					<label class="mt-2 h5" for="size" v-html="size[el.size].nama" />
				</div>
			</div>
			<CCol md="3">
				<CInput
					label="Jumlah"
					type="number"
					v-model.number="el.jumlah"
					placeholder="Jumlah"
				/>
			</CCol>
			<CCol md="3">
				<CInput
					label="Stock"
					type="number"
					v-model.number="el.stock"
					placeholder="Stock"
				/>
			</CCol>
			<CCol md="3">
				<button
					@click="changeStock(i)"
					class="btn btn-sm btn-warning"
					style="margin-top: 25px"
				>
					CHANGE
				</button>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { size, debounce } from "@/plugins/utils";
export default {
	components: {},
	data() {
		return {
			size,
			sesuais: [],
		};
	},
	props: {
		rowData: {
			type: Object,
			required: true,
		},
		rowIndex: {
			type: Number,
		},
	},
	methods: {
		changeStock: debounce(async function (i) {
			if (this.rowData.productItems[i].id <= 1408) {
				const frm = {
					stock: this.rowData.productItems[i].stock,
					jumlah: this.rowData.productItems[i].jumlah,
				};
				//console.log(this.rowData.productItems[i]);
				try {
					let datar = {};
					const { data } = await this.$http.put(
						"product-item/" + this.rowData.productItems[i].id,
						frm
					);
					datar = data;

					if (datar.status == "success") {
						this.$toast.success("Success ! " + datar.message);
					} else {
						this.$swal("Fail !", datar.message, "error");
					}
				} catch (error) {
					this.$swal("Error !", error.message, "error");
				}
			} else {
				this.$swal(
					"Peringatan !",
					"Produk item yang bisa diedit hanya PO 0001 sampai PO 0005",
					"warning"
				);
			}
		}, 1000),
	},
};
</script>
